@import url('https://fonts.googleapis.com/css2?family=Andika&family=Staatliches&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



/* Tambahkan ini dalam file CSS Anda atau gunakan inline styling */
.marquee {
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 30s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  

  /* Add this CSS to your stylesheet or a separate CSS file */
.pulse-animation {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
